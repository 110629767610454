import React from 'react';

const user = {
    name: 'Krillin',
    id: 1,
    imageUrl: 'https://i1.sndcdn.com/avatars-yznjDu4UE3qKD93N-VK0GHA-t500x500.jpg',
    imageSize: 90,
  };


function Profile() {
  return (
    <>
        <div className='avatar'>
        <p>Welcome <a href="#profile">{user.name}</a>!</p>
         <img
            src={user.imageUrl}
            alt={'Photo of ' + user.name}
            style={{
            width: user.imageSize,
            height: user.imageSize
            }}
        />
        </div>    
    </>
  );
}

export default Profile