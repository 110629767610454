import React from 'react';
import logo from './logo.svg';
import './App.css';
import TestButton from './components/TestButton';
import Profile from './components/Profile';
import packageJson from '../package.json';


const mockUsers = [
  {name: 'Goku', id: 2},
  {name: 'Yamcha', id: 3},
  {name: 'Bulma', id: 4}
  ];


  /*
export interface Env {
	DB: D1Database;
}

export const onRequest: PagesFunction<Env> = async (context) => {
  // Create a prepared statement with our query
  const ps = context.env.DB.prepare('SELECT * from Customers');
  const data = await ps.first();

  return Response.json(data);
}
*/

function UserList() {
  const listItems = mockUsers.map(user =>
    <li key={user.id}>
      {user.name}
    </li>
  );

  return (
    <ul>
      {listItems}
    </ul>
  );
}

function App() {
  return (
    <div className="App">
          <div className="topnav">
              <a className="active" href="#home">Home</a>
              <a href="#entries">Entries</a>
              <a href="#settings">Settings</a>
              <a href="#calendar">Calendar</a>
              <Profile />
          </div>
            
          <header className="App-header">
            <h1>Training Tracker</h1>
          </header>  

          <body className='App-body'>
            <p> A simple website using Cloudflare tech stack, such as Cloudflare Pages, D1 and R2. 
            </p>
            <TestButton />
            <UserList />
            
          </body>

          <footer className='App-footer'>          
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a> | {packageJson.version}
            <img src={logo} className="App-logo" alt="logo" />
          </footer>
      </div>
      

  );
}

export default App;
