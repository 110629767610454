import React from 'react';
import { useState } from 'react';

function TestButton() {
  const [count, setCount] = useState(0);

  function handleClick() {
    setCount(count + 1);
    alert('Hello ' + count);
  }

  return (
    <button onClick={handleClick}>
      Test {count} button
    </button>
  );
}

export default TestButton